<template>
  <section
    class="relative bg-women-dark border-red border-2 py-24"
    id="sponsors"
  >
    <inner-constraint class="relative">
      <heading4 class="mb-6">Sponsors of Women in Tech Twente</heading4>
      <heading3 class="text-white">Our partners</heading3>
      <div
        class="justify-center text-center mt-14 flex flex-col-reverse flex-nowrap md:flex-row md:gap-x-8 lg:gap-x-12"
      >
        <div class="mt-4 flex shrink-0 flex-col gap-y-12 md:w-64 md:lg:w-96">
          <Sponsor
            :key="sponsor.title"
            :title="sponsor.title"
            button-text="Visit website"
            :href="sponsor.href"
            :subtitle="sponsor.subtitle"
            v-for="sponsor in sponsors"
          ></Sponsor>
        </div>
        <div
          class="hidden md:block md:relative md:flex md:w-4/12 md:items-center md:justify-center md:rounded-full bg-white md:px-8 md:py-2 md:py-4 font-bold text-plu-black transition md:p-2"
        >
          <Image alt="sponsors" class="w-9/12 pb-6" :src="SponsorLogos" />
        </div>
      </div>
    </inner-constraint>
  </section>
</template>
<script setup>
import Heading4 from "@/Components/texts/Heading4.vue";
import InnerConstraint from "@/Components/InnerConstraint.vue";
import Heading3 from "@/Components/texts/Heading3.vue";
import Sponsor from "@/Components/locations/sponsor.vue";
import SponsorLogos from "~/images/black-white/logos-sponsors.png";
import Image from "@/Components/images/Image.vue";

const sponsors = [
  {
    title: "El Niño",
    subtitle: "Digital Agency",
    href: "https://elnino.tech/",
  },
  {
    title: "TMC",
    subtitle: "High Tech Consultancy",
    href: "https://tmc-employeneurship.com/",
  },
];
</script>
