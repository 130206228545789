<template>
  <MenuLink @click="click" href="#how-it-works-block">Our mission </MenuLink>
  <MenuLink @click="click" href="#sponsors">Partners</MenuLink>
  <MenuLink @click="click" href="#faq-block"> FAQ </MenuLink>
</template>
<script setup>
import MenuLink from "@/Components/menu/MenuLink.vue";
const emits = defineEmits(["click"]);
function click() {
  emits("click");
}
</script>
