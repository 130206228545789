<template>
    <h4
        class="text-md text-women-lilac font-bold uppercase tracking-widest md:text-2xl"
    >
        <slot></slot>
    </h4>
</template>
<style scoped>
:deep(span) {
    @apply text-women-lilac;
}
</style>
<script setup></script>
