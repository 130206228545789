<template>
  <div class="flex flex-col">
    <paragraph class="mb-1">
      <span class="mb-2 text-xl"
        >{{ props.title }} {{ props.subtitle ? "-" : "" }}
      </span>
      {{ props.subtitle }}
    </paragraph>
    <div class="mt-8">
      <primary-button :href="props.href">
        {{ props.buttonText }}
      </primary-button>
    </div>
  </div>
</template>
<script setup>
import PrimaryButton from "@/Components/buttons/PrimaryButton.vue";
import Paragraph from "@/Components/texts/Paragraph.vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    default: "",
    type: String,
  },
  href: {
    type: String,
    required: true,
  },
  buttonText: {
    type: String,
    required: true,
  },
});
</script>
