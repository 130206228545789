<template>
  <component
    :disabled="disabled"
    :is="isExternal ? 'a' : href ? Link : 'button'"
    :href="href"
    :target="isExternal ? '_blank' : null"
    class="bg-women-pink rounded-full px-8 py-4 font-bold text-plu-black transition hover:bg-women-light-pink"
    :class="disabled ? 'cursor-not-allowed opacity-50' : ''"
  >
    <slot></slot>
  </component>
</template>
<script setup>
import { Link } from "@inertiajs/vue3";
import { computed } from "vue";

const props = defineProps({
  href: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const isExternal = computed(() => {
  return props.href && props.href.startsWith("http");
});
</script>
