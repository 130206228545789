<template>
  <section class="bg-white py-28 md:py-36" id="how-it-works-block">
    <inner-constraint v-animate-in>
      <heading4 class="mb-6 text-center">Our mission</heading4>
      <heading3 class="text-center">What to expect from our community</heading3>

      <div
        v-animate-in
        class="mt-16 flex flex-col items-center gap-x-4 gap-y-16 md:mt-28 md:flex-row md:flex-nowrap md:items-start md:justify-between"
      >
        <template :key="step.title" v-for="(step, index) in steps">
          <HowItWorksStep
            class="shrink-0"
            :icon="step.icon"
            :title="step.title"
            :subtitle="step.subtitle"
          ></HowItWorksStep>
          <img
            class="shrink-1 mt-12 hidden h-1 w-full object-cover object-center md:block"
            alt="divider"
            v-if="index < steps.length - 1"
            :src="Line"
          />
        </template>
      </div>
    </inner-constraint>
  </section>
</template>
<script setup>
import Heading3 from "@/Components/texts/Heading3.vue";
import Heading4 from "@/Components/texts/Heading4.vue";
import HowItWorksStep from "@/Components/steps/HowItWorksStep.vue";
import InnerConstraint from "@/Components/InnerConstraint.vue";
import Line from "~/images/lines/dotted-line.svg";
import SpeakerIcon from "@/Components/images/icons/SpeakerIcon.vue";
import NetworkIcon from "@/Components/images/icons/NetworkIcon.vue";
import RocketIcon from "@/Components/images/icons/RocketIcon.vue";

const steps = [
  {
    icon: SpeakerIcon,
    title: "Engaging meetups",
    subtitle:
      "Join regular gatherings to connect, share experiences, and learn from one another",
  },
  {
    icon: NetworkIcon,
    title: "Supportive network",
    subtitle:
      "Build meaningful connections with like-minded women in the tech industry",
  },
  {
    icon: RocketIcon,
    title: "Empowerment",
    subtitle:
      "Be part of a community that celebrates your achievements and supports your professional growth",
  },
];
</script>
