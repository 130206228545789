<template>
  <svg
    fill="#ffffff"
    height="48"
    width="48"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 490.258 490.258"
    xml:space="preserve"
    stroke="#ffffff"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <g>
        <path
          d="M107.378,342.861c27.184,0,49.297-21.961,49.297-48.961c0-27.001-22.114-48.961-49.297-48.961 c-27.185,0-49.298,21.96-49.298,48.961C58.08,320.9,80.193,342.861,107.378,342.861z M107.378,265.79 c15.688,0,28.446,12.615,28.446,28.11s-12.758,28.11-28.446,28.11c-15.689,0-28.447-12.615-28.447-28.11 S91.689,265.79,107.378,265.79z"
        ></path>
        <path
          d="M333.553,293.9c0,27,22.114,48.961,49.297,48.961s49.297-21.961,49.297-48.961c0-27.001-22.114-48.961-49.297-48.961 S333.553,266.899,333.553,293.9z M382.85,265.79c15.689,0,28.446,12.615,28.446,28.11s-12.757,28.11-28.446,28.11 c-15.689,0-28.446-12.615-28.446-28.11S367.16,265.79,382.85,265.79z"
        ></path>
        <path
          d="M243.092,97.932c27.185,0,49.298-21.96,49.298-48.961c0-27-22.114-48.971-49.298-48.971 c-27.183,0-49.297,21.971-49.297,48.971C193.795,75.972,215.909,97.932,243.092,97.932z M243.092,20.851 c15.689,0,28.447,12.614,28.447,28.12c0,15.496-12.758,28.11-28.447,28.11c-15.688,0-28.446-12.615-28.446-28.11 C214.646,33.465,227.404,20.851,243.092,20.851z"
        ></path>
        <path
          d="M433.777,369.031c-30.318-18.032-70.688-16.677-101.813-0.01c-5.779,3.096-14.862,9.222-24.062,18.386l-54.384-74.739 v-67.786c30.571-1.024,60.719-6.535,89.758-16.446c4.195-1.436,7.025-5.427,7.056-9.866c0-0.021,0.02-1.028,0.02-1.049 c0-20.403-7.051-64.355-56.352-93.677c-30.318-18.031-70.688-16.677-101.813-0.01c-15.811,8.471-56.362,39.6-56.362,93.687 l0.03,1.049c0,4.459,2.841,8.43,7.056,9.866c29.038,9.911,59.187,15.422,89.757,16.446v67.786l-52.694,72.416 c-6.116-5.648-13.28-11.063-21.669-16.052c-30.318-18.032-70.688-16.677-101.813-0.01c-15.811,8.471-56.362,39.6-56.362,93.687 l0.03,1.049c0,4.459,2.841,8.43,7.056,9.866c32.335,11.036,66.045,16.636,100.182,16.636c34.138,0,67.848-5.6,100.183-16.636 c4.195-1.436,7.025-5.427,7.056-9.866c0-0.021,0.02-1.028,0.02-1.049c0-13.769-3.215-38.263-20.456-61.748l48.892-67.191 l51.014,70.107c-10.386,15.035-18.505,34.633-18.505,58.831l0.03,1.049c0,4.459,2.841,8.43,7.056,9.866 c32.335,11.036,66.045,16.636,100.182,16.636c34.138,0,67.848-5.6,100.183-16.636c4.195-1.436,7.025-5.427,7.056-9.866 c0-0.021,0.02-1.028,0.02-1.049C490.129,442.305,483.078,398.353,433.777,369.031z M202.033,142.221 c25.097-13.38,57.207-13.72,82.121,0c34.44,18.966,44.024,54.775,45.103,68.865c-55.854,17.43-116.483,17.44-172.337-0.011 C156.919,178.151,185.115,151.24,202.033,142.221z M21.224,456.262c0-32.924,28.196-59.835,45.114-68.854 c25.097-13.381,57.207-13.72,82.121,0c34.44,18.966,44.024,54.774,45.103,68.865C137.707,473.703,77.078,473.714,21.224,456.262z M296.697,456.262c0-32.924,28.196-59.835,45.114-68.854c25.097-13.381,57.207-13.72,82.121,0 c34.44,18.966,44.024,54.774,45.103,68.865C413.18,473.703,352.55,473.714,296.697,456.262z"
        ></path>
      </g>
    </g>
  </svg>
</template>
